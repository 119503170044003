.box {
  width: 100%;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
.image2 {
  position: absolute;
  top: 30px;
  left: 30px;
  border: 1px green solid;
}

.container {
  position: relative;
}
#clip {
  position: absolute;
  clip: rect(0, 100px, 200px, 0);
  /* clip: shape(top, right, bottom, left); NB 'rect' is the only available option */
}

.a {
  text-decoration: underline;
}
