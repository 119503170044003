.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.stickyTypo {
  position: fixed;
  top: 50;
  width: 100%;
}

.fixed {
  position: relative;

  width: 100%;
}
