.parent {
  width: 90vw;
  overflow-x: hidden;
  float: left;
}
.child {
  width: 1000px;
  float: left;
  font-size: 15px;
  font-family: arial;
  padding: 10px;
  cursor: pointer;
}

.verticalParent {
  width: 100vw;
  overflow-y: hidden;
  float: left;
}
.verticalChild {
  width: 100vw;
  float: left;
  font-size: 15px;
  font-family: arial;
  cursor: pointer;
}
