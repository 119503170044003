.background_profile {
  /*   background-image: url("../images/mann.webp"); */
  background-image: url("../data/Büro/Buero-02.jpg");
  background-size: 100% 100%;
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  top: 30px;
  left: 30px;
  border: 1px green solid;
}
